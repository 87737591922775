import { DataTable } from '@/components/DataTable'
import Page from '@/components/Page'

import CreateOrUpdateVideo from './CreateOrUpdateVideo'
import { columns } from './columns'
import { LEARN_TABS } from '@/constants/tabs'

const Videos: React.FC = () => {
  return (
    <Page
      permissionSlug='learn_view'
      pageTitle='Learn Creators'
      header={<CreateOrUpdateVideo url='admin/learn/videos' />}
      tabs={LEARN_TABS}
    >
      <DataTable
        url='admin/learn/videos'
        columns={columns}
        totalText='videos'
        defaultSortBy='views'
        defaultSortOrder='desc'
      />
    </Page>
  )
}

export default Videos
