import { ILimits } from '@/types/account/limits.interface'

import ExchangeLimits from './Exchange'
import StarterLimits from './Starter'

interface LimitsCardsProps {
  hasExchange: boolean
  hasStarter: boolean
  limits: ILimits
}

const UserLimits: React.FC<LimitsCardsProps> = ({
  limits,
  hasExchange,
  hasStarter
}) => {
  return (
    <>
      {hasExchange && <ExchangeLimits limits={limits.exchange} />}
      {hasStarter && <StarterLimits limits={limits.starter} />}
      {!hasExchange && !hasStarter && (
        <p className='text-lg font-semibold text-foreground/50'>
          No Limits available for this user
        </p>
      )}
    </>
  )
}

export default UserLimits
