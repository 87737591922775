import { DataTable } from '@/components/DataTable'
import Page from '@/components/Page'

import CreateOrUpdateCreator from './CreateOrUpdateCreator'
import { columns } from './columns'
import { LEARN_TABS } from '@/constants/tabs'

const Creators: React.FC = () => {
  return (
    <Page
      permissionSlug='learn_view'
      pageTitle='Learn Creators'
      header={<CreateOrUpdateCreator url='admin/learn/creators' />}
      tabs={LEARN_TABS}
    >
      <DataTable
        url='admin/learn/creators'
        columns={columns}
        totalText='creators'
        defaultSortBy='name'
        defaultSortOrder='asc'
      />
    </Page>
  )
}

export default Creators
