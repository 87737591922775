import { DataTable } from '@/components/DataTable'
import Page from '@/components/Page'

import CreateOrUpdateCategory from './CreateOrUpdateCategory'
import { columns } from './columns'
import { LEARN_TABS } from '@/constants/tabs'

const Categories: React.FC = () => {
  return (
    <Page
      permissionSlug='learn_view'
      pageTitle='Learn Categories'
      header={<CreateOrUpdateCategory url='admin/learn/categories' />}
      tabs={LEARN_TABS}
    >
      <DataTable
        url='admin/learn/categories'
        columns={columns}
        totalText='categories'
        defaultSortBy='name'
        defaultSortOrder='asc'
      />
    </Page>
  )
}

export default Categories
