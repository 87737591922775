import BigNumber from 'bignumber.js'
import { type ClassValue, clsx } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export const shortenString = (str: string, len: number) => {
  if (str.length <= len) return str
  return str.slice(0, len) + '...'
}

export const EMPTY_NUMBER = ' -'

export const millisatoshisToBTC = (millisatoshis: BigNumber): BigNumber => {
  const millisatsPerBitcoin = new BigNumber(100000000000)

  return millisatoshis.div(millisatsPerBitcoin)
}

export const formatBtc = (num: string | undefined) => {
  if (!num) {
    return EMPTY_NUMBER
  }

  const val = new BigNumber(num)
  return val >= BigNumber(9999999) ? val.toFormat(0) : val.toFormat(8)
}

export const mstasToBtcAndFormat = (num: string | number | undefined) => {
  if (!num) {
    return EMPTY_NUMBER
  }

  const val = millisatoshisToBTC(new BigNumber(num))
  return val >= BigNumber(9999999) ? val.toFormat(0) : val.toFormat(8)
}

export const msatsToUSD = (msats?: string, btcPrice?: number): BigNumber => {
  if (!msats || !btcPrice) {
    return new BigNumber(0)
  }
  const btc = millisatoshisToBTC(BigNumber(msats))
  return btc.times(btcPrice)
}

export const formatNumber = (
  num: string | number | undefined,
  empty?: string
) => {
  if (!num) {
    return empty || EMPTY_NUMBER
  }
  const formattedNumber = new BigNumber(num)
  return formattedNumber.toFormat(2)
}

export const formatInteger = (
  num: string | number | undefined,
  empty?: string
) => {
  if (!num) {
    return empty || EMPTY_NUMBER
  }

  const formattedNumber = new BigNumber(num)
  return formattedNumber.toFormat(0)
}

export const msatsToSats = (num: string | undefined, empty?: string) => {
  if (!num) {
    return empty || EMPTY_NUMBER
  }

  const mstats = new BigNumber(num)
  const one = new BigNumber(1000)
  return mstats.div(one).integerValue(BigNumber.ROUND_FLOOR)
}

export const msatsToUsd = (
  msats?: number | string,
  btcPrice?: number,
  empty?: string
) => {
  if (!msats || !btcPrice) {
    return empty || EMPTY_NUMBER
  }

  return BigNumber((Number(msats) * btcPrice) / 100_000_000_000).toFormat(2)
}

export const omitKeys = <T, Keys extends keyof T>(obj: T, keys: Keys[]) => {
  const clone = { ...obj }
  keys.forEach(key => {
    delete clone[key]
  })

  return clone as Omit<T, Keys>
}
