import ProgressBar from '@/components/ProgressBar'

import {
  ExchangeDataInterface,
  FlashDataInterface,
  IUserMetrics,
  StarterDataInterface,
  StashDataInterface
} from '@/types/metrics/metrics.interface'

import {
  EMPTY_NUMBER,
  formatBtc,
  formatInteger,
  formatNumber,
  mstasToBtcAndFormat
} from '@/utils'

const formatUserData = (data?: IUserMetrics) => {
  if (!data) {
    return {
      dailyActive: {
        label: EMPTY_NUMBER,
        description: 'Daily Active'
      },
      weeklyActive: {
        label: EMPTY_NUMBER,
        description: 'Weekly Active'
      },
      monthlyActive: {
        label: EMPTY_NUMBER,
        description: 'monthly Active'
      },
      totalAccounts: {
        label: EMPTY_NUMBER,
        description: 'Total Accounts'
      },
      satoshiPlus: {
        label: EMPTY_NUMBER,
        description: 'Satoshi+'
      },
      referredUsers: {
        label: EMPTY_NUMBER,
        description: 'Referred Users'
      },
      waitlistEmails: {
        label: EMPTY_NUMBER,
        description: 'Waitlist Emails'
      },
      totalDownloads: {
        label: EMPTY_NUMBER,
        description: 'Total Downloads'
      }
    }
  }

  return {
    dailyActive: {
      label: formatInteger(data?.active_last_day),
      description: 'Daily Active'
    },
    weeklyActive: {
      label: formatInteger(data?.active_last_week),
      description: 'Weekly Active'
    },
    monthlyActive: {
      label: formatInteger(data?.active_last_month),
      description: 'monthly Active'
    },
    totalAccounts: {
      label: formatInteger(data?.total),
      description: 'Total Accounts'
    },
    satoshiPlus: {
      label: formatInteger(data?.total_satoshi_plus),
      description: 'Satoshi+'
    },
    referredUsers: {
      label: formatInteger(data?.total_referred),
      description: 'Referred Users'
    },
    waitlistEmails: {
      label: formatInteger(data?.total_waitlisted),
      description: 'Waitlist Emails'
    },
    totalDownloads: {
      label: formatInteger(data?.total_downloads),
      description: 'Total Downloads'
    }
  }
}

const formatExchangeData = (data: ExchangeDataInterface | undefined) => {
  return {
    bitcoinUnderCustody: {
      description: 'Bitcoin Under Custody',
      tabs: [
        {
          key: 'current',
          label: 'Current',
          value: `${formatBtc(String(data?.custodied_btc))} BTC`
        }
      ]
    },
    dollarsUnderCustody: {
      description: 'Dollars Under Custody',
      tabs: [
        {
          key: 'current',
          label: 'Current',
          value: `$${formatNumber(String(data?.custodied_usd))}`
        }
      ]
    },
    buyVolume: {
      description: 'Buy Volume',
      tabs: [
        {
          key: 'day',
          label: '24hr',
          value: `$${formatNumber(String(data?.trades_buy_day_usd))}`
        },
        {
          key: 'week',
          label: 'Week',
          value: `$${formatNumber(String(data?.trades_buy_week_usd))}`
        },
        {
          key: 'month',
          label: '30d',
          value: `$${formatNumber(data?.trades_buy_month_usd)}`
        }
      ]
    },
    sellVolume: {
      description: 'Sell Volume',
      tabs: [
        {
          key: 'day',
          label: '24hr',
          value: `$${formatNumber(data?.trades_sell_day_usd)}`
        },
        {
          key: 'week',
          label: 'Week',
          value: `$${formatNumber(data?.trades_sell_week_usd)}`
        },
        {
          key: 'month',
          label: '30d',
          value: `$${formatNumber(data?.trades_sell_month_usd)}`
        }
      ]
    },
    depositVolume: {
      description: 'Deposit volume',
      tabs: [
        {
          key: 'week',
          label: 'Week',
          value: (
            <div className='flex w-2/3 flex-col items-center gap-4 text-xl font-bold text-blue500 lg:text-2xl'>
              <p>
                ${formatNumber(data?.deposit_week_usd)}
                {' / '}$
                {formatNumber(data?.limits.funding_deposit_weekly_limit)}
              </p>
              <ProgressBar
                value={
                  Number(data?.deposit_week_usd ?? 0) /
                  Number(data?.limits.funding_deposit_weekly_limit ?? 1)
                }
                color='blue500'
              />
            </div>
          )
        },
        {
          key: 'month',
          label: 'Month',
          value: (
            <div className='flex w-2/3 flex-col items-center gap-4 text-xl font-bold text-blue500 lg:text-2xl'>
              <p>
                ${formatNumber(data?.deposit_month_usd)}
                {' / '}$
                {formatNumber(data?.limits.funding_deposit_monthly_limit)}
              </p>
              <ProgressBar
                value={
                  Number(data?.deposit_month_usd ?? 0) /
                  Number(data?.limits.funding_deposit_monthly_limit ?? 1)
                }
                color='blue500'
              />
            </div>
          )
        }
      ]
    },
    withdrawalVolume: {
      description: 'Withdrawal Volume',
      tabs: [
        {
          key: 'week',
          label: 'Week',
          value: (
            <div className='flex w-2/3 flex-col items-center gap-4 text-xl font-bold text-blue500 lg:text-2xl'>
              <p>
                ${formatNumber(data?.withdrawal_week_usd)}
                {' / '}$
                {formatNumber(data?.limits.funding_withdrawal_weekly_limit)}
              </p>
              <ProgressBar
                value={
                  Number(data?.withdrawal_week_usd ?? 0) /
                  Number(data?.limits.funding_withdrawal_weekly_limit ?? 1)
                }
                color='blue500'
              />
            </div>
          )
        },
        {
          key: 'month',
          label: 'Month',
          value: (
            <div className='flex w-2/3 flex-col items-center gap-4 text-xl font-bold text-blue500 lg:text-2xl'>
              <p>
                ${formatNumber(data?.withdrawal_month_usd)}
                {' / '}$
                {formatNumber(data?.limits.funding_withdrawal_monthly_limit)}
              </p>
              <ProgressBar
                value={
                  Number(data?.withdrawal_month_usd ?? 0) /
                  Number(data?.limits.funding_withdrawal_monthly_limit ?? 1)
                }
                color='blue500'
              />
            </div>
          )
        }
      ]
    }
  }
}

const formatFlashData = (data: FlashDataInterface | undefined) => {
  return {
    totalBalance: {
      label: `$${formatNumber(data?.total_balance_usd)}`,
      description: `${mstasToBtcAndFormat(data?.total_balance_msats)} BTC`,
      subDescription: 'Total Balance'
    },
    volumeReceived: {
      label: `$${formatNumber(data?.volume_received_usd)}`,
      description: `${mstasToBtcAndFormat(data?.volume_received_msats)} BTC`,
      subDescription: 'Volume Received'
    },
    volumeSent: {
      label: `$${formatNumber(data?.volume_sent_usd)}`,
      description: `${mstasToBtcAndFormat(data?.volume_sent_msats)} BTC`,
      subDescription: 'Volume Sent'
    },
    paymentsMade: {
      description: 'Payments Made',
      tabs: [
        {
          key: 'hour',
          label: '1hr',
          value: `${formatInteger(data?.payments_made_1h)}`
        },
        {
          key: 'day',
          label: '24hr',
          value: `${formatInteger(data?.payments_made_24h)}`
        },
        {
          key: 'month',
          label: '30d',
          value: `${formatInteger(data?.payments_made_30d)}`
        },
        {
          key: 'all',
          label: 'All',
          value: `${formatInteger(data?.payments_made_all)}`
        }
      ]
    }
  }
}

const formatStashData = (data: FlashDataInterface | undefined) => {
  return {
    totalBalance: {
      label: `$${formatNumber(data?.total_balance_usd)}`,
      description: `${mstasToBtcAndFormat(data?.total_balance_msats)} BTC`,
      subDescription: 'Total Balance'
    },
    volumeReceived: {
      label: `$${formatNumber(data?.volume_received_usd)}`,
      description: `${mstasToBtcAndFormat(data?.volume_received_msats)} BTC`,
      subDescription: 'Volume Received'
    },
    volumeSent: {
      label: `$${formatNumber(data?.volume_sent_usd)}`,
      description: `${mstasToBtcAndFormat(data?.volume_sent_msats)} BTC`,
      subDescription: 'Volume Sent'
    },
    paymentsMade: {
      description: 'Payments Made',
      tabs: [
        {
          key: 'hour',
          label: '1hr',
          value: `${formatInteger(data?.payments_made_1h)}`
        },
        {
          key: 'day',
          label: '24hr',
          value: `${formatInteger(data?.payments_made_24h)}`
        },
        {
          key: 'month',
          label: '30d',
          value: `${formatInteger(data?.payments_made_30d)}`
        },
        {
          key: 'all',
          label: 'All',
          value: `${formatInteger(data?.payments_made_all)}`
        }
      ]
    }
  }
}

const formatStarterData = (data: StarterDataInterface | undefined) => {
  return {
    totalBalance: {
      label: `$${formatNumber(data?.total_balance_usd)}`,
      description: `${mstasToBtcAndFormat(data?.total_balance_msats)} BTC`,
      subDescription: 'Total Balance'
    },
    volumeReceived: {
      label: `$${formatNumber(data?.volume_received_usd)}`,
      description: `${mstasToBtcAndFormat(data?.volume_received_msats)} BTC`,
      subDescription: 'Volume Received'
    },
    volumeSent: {
      label: `$${formatNumber(data?.volume_sent_usd)}`,
      description: `${mstasToBtcAndFormat(data?.volume_sent_msats)} BTC`,
      subDescription: 'Volume Sent'
    },
    paymentsMade: {
      description: 'Payments Made',
      tabs: [
        {
          key: 'hour',
          label: '1hr',
          value: `${formatInteger(data?.payments_made_1h)}`
        },
        {
          key: 'day',
          label: '24hr',
          value: `${formatInteger(data?.payments_made_24h)}`
        },
        {
          key: 'month',
          label: '30d',
          value: `${formatInteger(data?.payments_made_30d)}`
        },
        {
          key: 'all',
          label: 'All',
          value: `${formatInteger(data?.payments_made_all)}`
        }
      ]
    }
  }
}

export const formatMetricsData = ({
  userData,
  exchange,
  flash,
  starter,
  stash
}: {
  userData?: IUserMetrics
  exchange?: ExchangeDataInterface
  flash?: FlashDataInterface
  starter?: StarterDataInterface
  stash?: StashDataInterface
}) => {
  return {
    userData: formatUserData(userData),
    exchange: formatExchangeData(exchange),
    flash: formatFlashData(flash),
    stash: formatStashData(stash),
    starter: formatStarterData(starter)
  }
}
