import { DataTable } from '@/components/DataTable'
import Page from '@/components/Page'

import { SendNotification } from '@/routes/Notifications/SendNotification'
import { columns } from '@/routes/Notifications/columns'

export function Notifications() {
  return (
    <Page
      permissionSlug='users_send_notifications'
      pageTitle='Notifications'
      header={<SendNotification />}
    >
      <DataTable
        url='/admin/notifications'
        columns={columns}
        totalText='notifications'
        tableClassName='md:!max-h-[calc(100vh-550px)]'
      />
    </Page>
  )
}
