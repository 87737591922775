import { DataTable } from '@/components/DataTable'
import Page from '@/components/Page'

import { ORGANIZATION_TABS } from '@/constants/tabs'
import { columns } from '@/routes/Organization/Roles/columns'

export const Roles = () => {
  return (
    <Page
      permissionSlug='organization_view'
      pageTitle='Roles'
      tabs={ORGANIZATION_TABS}
    >
      <DataTable
        url='admin/roles'
        totalText='roles'
        columns={columns}
        tableClassName='md:!max-h-[calc(100vh-550px)]'
      />
    </Page>
  )
}
