import { DataTable } from '@/components/DataTable'
import ReferMetrics from '@/components/Metrics/Refer'
import Page from '@/components/Page'

import { columns } from './columns'
import { REFER_TABS } from '@/constants/tabs'

export const ReferTransactions = () => {
  return (
    <Page
      permissionSlug='refer_view'
      pageTitle='Refer Transactions'
      tabs={REFER_TABS}
    >
      <ReferMetrics />
      <DataTable
        url='admin/refer/transactions'
        totalText='transactions'
        columns={columns}
        tableClassName='md:!max-h-[calc(100vh-550px)]'
      />
    </Page>
  )
}
