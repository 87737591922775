import { DataTable } from '@/components/DataTable'
import Page from '@/components/Page'

import { columns } from './columns'
import { ORGANIZATION_TABS } from '@/constants/tabs'

export const Configurations = () => {
  return (
    <Page
      permissionSlug='organization_view'
      pageTitle='Configurations'
      tabs={ORGANIZATION_TABS}
    >
      <DataTable
        url='admin/configuration'
        totalText='configurations'
        columns={columns}
        tableClassName='md:!max-h-[calc(100vh-550px)]'
      />
    </Page>
  )
}
