import React from 'react'

import { Button } from '@/components/Button'

import { cn } from '@/utils'

interface EditButtonProps {
  title: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  className?: string
}

export const EditButton = ({ title, onClick, className }: EditButtonProps) => {
  return (
    <Button
      title={title}
      onClick={onClick}
      className={cn(
        'justify-center whitespace-nowrap bg-white/10 px-[20px] text-center text-[12px] font-bold text-white transition-all',
        className
      )}
    />
  )
}
