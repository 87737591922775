import { useLocation, useNavigate } from 'react-router-dom'

import Icon from '@/components/Icon'
import ProtectedComponent from '@/components/ProtectedComponent'

import { useAuth } from '@/hooks/use-auth'

import { MENU_ITEMS, MenuItem } from '@/constants/sidebar'
import { ORGANIZATION_TABS } from '@/constants/tabs'
import { cn } from '@/utils'

export function Sidebar() {
  const { isAuthenticated } = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  if (!isAuthenticated) return null

  return (
    <aside
      style={{
        height: 'calc(100vh - 48px)'
      }}
      className='bg-grey900 sticky top-12 flex w-full max-w-20 flex-col items-start gap-[40px] overflow-hidden border-r border-white/15 pt-[40px] transition-all md:max-w-[280px]'
    >
      {MENU_ITEMS.map(
        ({ icon, permissionSlug, ...menuItem }: MenuItem, i: number) => {
          const active =
            menuItem.url === '/'
              ? location.pathname === menuItem.relatedUrl
              : location.pathname.startsWith(menuItem.relatedUrl)

          const menuButton = (
            <button
              key={i}
              className={cn(
                'flex w-full items-center justify-start gap-7 px-7 text-lg font-semibold md:gap-4',
                {
                  'text-foreground': active,
                  'text-foreground-400': !active
                }
              )}
              onClick={() => {
                navigate(menuItem.url)
              }}
            >
              <div className='flex size-6 flex-none items-center justify-center'>
                {icon}
              </div>
              <span>{menuItem.label}</span>
            </button>
          )

          return permissionSlug ? (
            <ProtectedComponent key={i} permissionSlug={permissionSlug}>
              {menuButton}
            </ProtectedComponent>
          ) : (
            menuButton
          )
        }
      )}

      <ProtectedComponent permissionSlug='organization_view'>
        <button
          className={cn(
            'mb-5 mt-auto flex w-full items-center justify-start gap-7 px-7 text-lg font-semibold md:gap-4',
            {
              'text-foreground': location.pathname.startsWith('/organization'),
              'text-foreground/50':
                !location.pathname.startsWith('/organization')
            }
          )}
          onClick={() => {
            navigate(ORGANIZATION_TABS[0].url)
          }}
        >
          <Icon icon='Organization' className='size-6' />
          <span
            className={cn('select-none text-lg font-semibold', {
              'text-foreground': location.pathname.startsWith('/organization'),
              'text-foreground/50':
                !location.pathname.startsWith('/organization')
            })}
          >
            Organization
          </span>
        </button>
      </ProtectedComponent>
    </aside>
  )
}
