import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import { AddButton } from '@/components/Buttons/AddButton'
import Field from '@/components/Field'
import { FormButton } from '@/components/FormButton'
import { Loader } from '@/components/Loader'

import {
  IUserUpdate,
  mapIUserToIUserUpdate
} from '@/types/account/user-update.interface'
import { IUser } from '@/types/account/user.interface'
import { KycStatus } from '@/types/enums'

import { errorCatch } from '@/api/error'

import { Drawer } from '../Drawer'
import Icon from '../Icon'

import api from '@/api'

interface Props {
  initial: IUser
}

const UserUpdate: React.FC<Props> = ({ initial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<IUserUpdate>({
    mode: 'onChange',
    defaultValues: mapIUserToIUserUpdate(initial)
  })

  useEffect(() => {
    reset(mapIUserToIUserUpdate(initial))
  }, [initial, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: ['update-user', initial.id],
    mutationFn: async (data: IUserUpdate) =>
      await api.patch(`/admin/users/${initial.id}`, {
        ...data
      }),
    onSuccess: () => {
      toast.success(`User ${initial.username} updated successfully`)
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(errorMessage || 'An error occurred while updating the user')
    }
  })

  const onSubmit: SubmitHandler<IUserUpdate> = async data => mutate(data)

  return (
    <>
      <AddButton title='Edit Details' onClick={() => setIsOpen(true)} />
      <Drawer
        contentClassName='bg-gray900 border-solid border-gray500/50 w-full sm:w-[480px]'
        open={isOpen}
        position='right'
        onClose={() => setIsOpen(false)}
      >
        <Loader type='absolute' loading={isPending} />
        <section className='flex h-full flex-col gap-2 overflow-y-auto p-4 md:p-8 '>
          <div className='flex flex-row items-center justify-between'>
            <p className='text-xl font-bold text-foreground'>
              Edit User Details
            </p>
            <Icon
              icon='Close'
              onClick={() => setIsOpen(false)}
              className='cursor-pointer fill-white opacity-50 transition-opacity hover:opacity-80 active:opacity-100'
            />
          </div>
          <form className='mt-4 flex flex-col gap-5'>
            <Field
              title='Email'
              placeholder='Email'
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /\S+@\S+\.\S+/,
                  message: 'Email is not valid'
                }
              })}
              error={errors.username?.message}
            />
            <Field
              title='Username'
              placeholder='@username'
              {...register('username', {
                required: 'Username is required',
                minLength: {
                  value: 3,
                  message: 'Username must be at least 3 characters'
                }
              })}
              error={errors.username?.message}
            />
            {(initial.kyc_status === KycStatus.VERIFIED ||
              initial.kyc_status === KycStatus.VERIFIED_NO_SSN) && (
              <>
                <Field
                  title='First Name'
                  placeholder='First Name'
                  {...register('first_name')}
                  error={errors.first_name?.message}
                />
                <Field
                  title='Last Name'
                  placeholder='Last Name'
                  {...register('last_name')}
                  error={errors.last_name?.message}
                />
              </>
            )}
            <Field
              title='Display Name'
              placeholder='Display Name'
              {...register('display_name', {
                minLength: {
                  value: 3,
                  message: 'Display Name must be at least 3 characters'
                }
              })}
              error={errors.display_name?.message}
            />
            <Field
              title='Accrued Balance'
              placeholder='Accrued Balance'
              {...register('accrued_balance_msats', {
                required: 'Accrued Balance is required',
                setValueAs: (value: string) => parseInt(value)
              })}
              error={errors.accrued_balance_msats?.message}
            />
            <Field
              title='Daily Earn Rate'
              placeholder='Daily Earn Rate'
              {...register('daily_earn_rate_msats', {
                required: 'Daily Earn Rate is required',
                setValueAs: (value: string) => parseInt(value)
              })}
              error={errors.daily_earn_rate_msats?.message}
            />
            <Field
              title='Streak Count'
              placeholder='Streak Count'
              {...register('streak_count', {
                required: 'Streak Count is required',
                setValueAs: (value: string) => parseInt(value)
              })}
              error={errors.streak_count?.message}
            />
            <Field
              title='Earnings'
              placeholder='Earnings'
              {...register('earnings_msats', {
                required: 'Earnings is required',
                setValueAs: (value: string) => parseInt(value)
              })}
              error={errors.earnings_msats?.message}
            />
            <Field
              title='Earned Unclaimed'
              placeholder='Earned Unclaimed'
              type='number'
              {...register('earned_unclaimed_msats', {
                required: 'Earned Unclaimed is required',
                setValueAs: (value: string) => parseInt(value)
              })}
              error={errors.earned_unclaimed_msats?.message}
            />
            <Field
              title='Referral Code'
              placeholder='Referral Code'
              {...register('referral_code', {
                required: 'Refer Earnings is required'
              })}
              error={errors.referral_code?.message}
            />
            <Field
              title='Refer Earnings'
              placeholder='Refer Earnings'
              type='number'
              {...register('refer_earnings_msats', {
                required: 'Refer Earnings is required'
              })}
              error={errors.refer_earnings_msats?.message}
            />
            <Field
              title='Refer Claimable'
              type='number'
              placeholder='Refer Claimable'
              {...register('refer_claimable_msats', {
                required: 'Refer Earnings is required'
              })}
              error={errors.refer_claimable_msats?.message}
            />

            <FormButton
              onClick={handleSubmit(onSubmit)}
              preventDefault
              stopPropagation
              title='Confirm'
              className='mt-2 bg-orange550 text-white'
            />
          </form>
        </section>
      </Drawer>
    </>
  )
}

export default UserUpdate
