import { Switch } from '@nextui-org/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { memo, useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import { AddButton } from '@/components/Buttons/AddButton'
import { EditButton } from '@/components/Buttons/EditButton'
import Field from '@/components/Field'
import { FormButton } from '@/components/FormButton'
import { Loader } from '@/components/Loader'
import { ErrorMessage } from '@/components/Message/ErrorMessage'
import { Modal } from '@/components/Modal'
import ProtectedComponent from '@/components/ProtectedComponent'

import {
  ICreateOrUpdateCreator,
  ICreator
} from '@/types/learn/creator.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'
import { AvatarUpload } from '@/routes/Learn/Creators/ImageUploader/AvatarUpload'
import { ImageUpload } from '@/routes/Learn/Creators/ImageUploader/ImageUpload'

interface Props {
  initial?: ICreator
  url: string
}

const CreateOrUpdateCreator: React.FC<Props> = memo(({ initial, url }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateOrUpdateCreator>({
    mode: 'onChange',
    defaultValues: initial
      ? {
          name: initial.name,
          username: initial.username,
          image: initial.image_full,
          avatar: initial.image,
          status: initial.status
        }
      : {
          name: '',
          username: '',
          image: '',
          avatar: '',
          status: 1
        }
  })

  useEffect(() => {
    reset(
      initial
        ? {
            name: initial.name,
            username: initial.username,
            image: initial.image_full,
            avatar: initial.image,
            status: initial.status
          }
        : {
            name: '',
            username: '',
            image: '',
            avatar: '',
            status: 1
          }
    )
  }, [initial, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-creator' : 'add-creator', initial?.id],
    mutationFn: async (creator: ICreateOrUpdateCreator) => {
      const formData = new FormData()
      if (!initial || initial?.image !== creator.image) {
        formData.append('image', creator.image)
      }
      if (!initial || initial?.image_full !== creator.avatar) {
        formData.append('avatar', creator.avatar)
      }

      formData.append('name', creator.name)
      if (creator.username) {
        formData.append('username', creator.username)
      }
      formData.append('status', String(creator.status))

      await api(url, {
        method: initial ? 'PATCH' : 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      })
    },
    onSuccess: () => {
      toast.success(
        initial ? 'Creator updated successfully' : 'Creator added successfully'
      )
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage ||
          (initial
            ? 'An error occurred while updating the creator.'
            : 'An error occurred while adding the creator.')
      )
    }
  })

  const onSubmit: SubmitHandler<ICreateOrUpdateCreator> = async data =>
    mutate(data)

  return (
    <>
      {initial ? (
        <ProtectedComponent permissionSlug='learn_update'>
          <EditButton onClick={() => setIsOpen(true)} title='Edit' />
        </ProtectedComponent>
      ) : (
        <ProtectedComponent permissionSlug='learn_create'>
          <AddButton title='Add Creator' onClick={() => setIsOpen(true)} />
        </ProtectedComponent>
      )}
      <Modal visible={isOpen} onClose={() => setIsOpen(false)}>
        <Loader type='absolute' loading={isPending} />
        <Modal.Header
          title={initial ? 'Edit Creator' : 'Create Creator'}
          closable
        />
        <Modal.Content>
          <form className='mt-4 flex flex-col gap-5'>
            <div className='flex gap-5'>
              <Controller
                name='image'
                control={control}
                rules={{
                  required: 'Image is required'
                }}
                render={({ field }) => (
                  <div className='flex flex-1 flex-col gap-2'>
                    <ImageUpload
                      image={initial?.image_full || ''}
                      onImage={f => {
                        field.onChange(f)
                      }}
                    />
                    {errors?.image?.message && (
                      <ErrorMessage message={errors?.image?.message} />
                    )}
                  </div>
                )}
              />
              <Controller
                name='avatar'
                control={control}
                rules={{
                  required: 'Image is required'
                }}
                render={({ field }) => (
                  <div className='flex flex-1 flex-col gap-2'>
                    <AvatarUpload
                      image={initial?.image || ''}
                      onImage={f => {
                        field.onChange(f)
                      }}
                    />
                    {errors?.avatar?.message && (
                      <ErrorMessage message={errors?.avatar?.message} />
                    )}
                  </div>
                )}
              />
            </div>
            <Field
              placeholder='name'
              {...register('name', {
                required: 'Name is required',
                minLength: {
                  value: 2,
                  message: 'Username must be at least 2 characters long'
                },
                maxLength: {
                  value: 50,
                  message: 'Username must be at most 50 characters long'
                }
              })}
              error={errors?.name?.message}
            />
            <Field
              placeholder='username'
              {...register('username', {
                minLength: {
                  value: 2,
                  message: 'Username must be at least 2 characters long'
                },
                maxLength: {
                  value: 50,
                  message: 'Username must be at most 50 characters long'
                }
              })}
              error={errors?.username?.message}
            />
            <Controller
              name='status'
              control={control}
              render={({ field }) => (
                <label className='flex cursor-pointer select-none items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>Visibility</p>
                  <Switch
                    color='success'
                    isSelected={field.value === 1}
                    onValueChange={isSelected =>
                      field.onChange(isSelected ? 1 : 0)
                    }
                  />
                </label>
              )}
            />
            <FormButton
              onClick={() => {
                handleSubmit(onSubmit)()
              }}
              preventDefault
              stopPropagation
              title='Confirm'
              className='mt-2 bg-orange550 text-white'
            />
          </form>
        </Modal.Content>
      </Modal>
    </>
  )
})

CreateOrUpdateCreator.displayName = 'CreateOrUpdateCreator'

export default CreateOrUpdateCreator
