import { NextUIProvider } from '@nextui-org/react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { Provider } from 'react-redux'
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'

import { AuthProvider } from '@/components/AuthProvider'
import { Layout } from '@/components/Layout'
import { ThemeProvider } from '@/components/Theme/Provider'

import BankFundings from './routes/Bank/BankFundings'
import BankTrades from './routes/Bank/BankTrades'
import BankTransfers from './routes/Bank/BankTransfers'
import BankConnections from './routes/Bank/Connections'
import BankEddRequests from './routes/Bank/EDDRequests'
import BankVerifications from './routes/Bank/Verifications'
import { Configurations } from './routes/Organization/Configurations'
import { ReferTransactions } from './routes/Refer/Transactions'
import { ReferUsers } from './routes/Refer/Users'
import { AccessCodes } from './routes/Users/AccessCodes'
import AccountGroups from './routes/Users/AccountGroups'
import AccountGroupById from './routes/Users/AccountGroups/ByID'
import UserDetails from './routes/Users/Details'
import { UsernameBlacklist } from './routes/Users/UsernameBlacklist'
import Earn from '@/routes/Earn'
import { Home } from '@/routes/Home'
import Categories from '@/routes/Learn/Categories'
import Creators from '@/routes/Learn/Creators'
import Videos from '@/routes/Learn/Videos'
import { Login } from '@/routes/Login'
import { Metrics } from '@/routes/Metrics'
import NotFound from '@/routes/NotFound'
import { Notifications } from '@/routes/Notifications'
import { Roles } from '@/routes/Organization/Roles'
import { TeamMembers } from '@/routes/Organization/TeamMembers'
import { Settings } from '@/routes/Settings'
import { Users } from '@/routes/Users/Users'
import store, { persistor } from '@/store'

const router = createBrowserRouter([
  {
    path: '/',
    element: <AuthProvider />,
    children: [
      {
        path: '/login',
        element: <Login />
      },
      {
        path: '*',
        element: <NotFound />
      },
      {
        element: <Layout />,
        children: [
          {
            path: '',
            element: <Home />
          },
          {
            path: 'settings',
            element: <Settings />
          },
          {
            path: 'users',
            children: [
              {
                path: '',
                element: <Users />
              },
              {
                path: 'username-blacklist',
                element: <UsernameBlacklist />
              },
              {
                path: 'access-codes',
                element: <AccessCodes />
              },
              {
                path: 'groups',
                children: [
                  {
                    path: '',
                    element: <AccountGroups />
                  },
                  {
                    path: ':id',
                    element: <AccountGroupById />
                  }
                ]
              },
              {
                path: ':userId',
                element: <UserDetails />
              }
            ]
          },
          {
            path: 'metrics',
            element: <Metrics />
          },
          {
            path: 'learn',
            children: [
              {
                path: '',
                element: <Navigate to='/learn/creators' replace />
              },
              {
                path: 'creators',
                element: <Creators />
              },
              {
                path: 'categories',
                element: <Categories />
              },
              {
                path: 'videos',
                element: <Videos />
              }
            ]
          },
          {
            path: 'earn',
            element: <Earn />
          },
          {
            path: 'refer',
            children: [
              {
                path: '',
                element: <Navigate to='/refer/users' replace />
              },
              {
                path: 'users',
                element: <ReferUsers />
              },
              {
                path: 'transactions',
                element: <ReferTransactions />
              }
            ]
          },
          {
            path: 'notifications',
            element: <Notifications />
          },
          {
            path: 'bank',
            children: [
              {
                path: '',
                element: <Navigate to='/bank/kyc' replace />
              },
              {
                path: 'kyc',
                element: <BankVerifications />
              },
              {
                path: 'payment-methods',
                element: <BankConnections />
              },
              {
                path: 'trades',
                element: <BankTrades />
              },
              {
                path: 'transfers',
                element: <BankTransfers />
              },
              {
                path: 'fundings',
                element: <BankFundings />
              },
              {
                path: 'edd-requests',
                element: <BankEddRequests />
              }
            ]
          },
          {
            path: 'organization',
            children: [
              {
                path: '',
                element: <Navigate to='/organization/members' replace />
              },
              {
                path: 'members',
                element: <TeamMembers />
              },
              {
                path: 'roles',
                element: <Roles />
              },
              {
                path: 'configurations',
                element: <Configurations />
              }
            ]
          }
        ]
      }
    ]
  }
])

function App() {
  const [client] = useState(
    new QueryClient({
      defaultOptions: {
        queries: {
          refetchOnWindowFocus: false,
          retry: false
        }
      }
    })
  )

  return (
    <QueryClientProvider client={client}>
      <NextUIProvider>
        <ThemeProvider defaultTheme='dark' storageKey='vite-ui-theme'>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <RouterProvider router={router} />
              <Toaster position='bottom-right' reverseOrder={false} />
            </PersistGate>
          </Provider>
        </ThemeProvider>
      </NextUIProvider>
    </QueryClientProvider>
  )
}

export default App
