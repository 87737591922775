import { DataTable } from '@/components/DataTable'
import Page from '@/components/Page'

import { columns } from './columns'
import { BANK_TABS } from '@/constants/tabs'

const BankVerifications: React.FC = () => {
  return (
    <Page
      permissionSlug='kyc_verifications_view'
      pageTitle='KYC verifications'
      tabs={BANK_TABS}
    >
      <DataTable url='admin/bank/verifications' columns={columns} />
    </Page>
  )
}

export default BankVerifications
