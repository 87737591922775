import { PropsWithChildren, useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import { useAuth } from '@/hooks/use-auth'

interface Props {
  permissionSlug?: string
  isPage?: boolean
}

const ProtectedComponent: React.FC<PropsWithChildren<Props>> = ({
  permissionSlug,
  isPage,
  children
}) => {
  const { admin, isSuperuser, permissions } = useAuth()

  const component = useMemo(() => {
    if (!permissionSlug || isSuperuser) {
      return children
    }

    if (permissions.some(permission => permission.slug === permissionSlug)) {
      return children
    }

    if (isPage) {
      return <Navigate to='/404' />
    }

    return null
  }, [children, admin, isSuperuser, permissions])

  return <>{component}</>
}

export default ProtectedComponent
