import { Loader } from '@/components/Loader'

import { cn, formatInteger } from '@/utils'

interface Props {
  count: number
  percentage: number
  label: string
  isLoading: boolean
  error: string | undefined
}

const UserMetric: React.FC<Props> = ({
  count,
  percentage,
  label,
  isLoading,
  error
}) => {
  return (
    <div
      title={error}
      className='relative flex flex-col items-center justify-center gap-2 overflow-hidden rounded-3xl border border-solid border-gray700 bg-gray800 py-5'
    >
      <Loader loading={!!isLoading} type='absolute' />
      {error && (
        <div className='absolute left-0 top-0 z-1 h-full w-full cursor-not-allowed bg-gray800/50' />
      )}
      <div
        title={label}
        className={cn(
          'long-text flex w-fit items-center gap-1 text-xl font-bold tracking-normal text-white md:gap-2 md:text-2xl',
          {
            blur: isLoading || error
          }
        )}
      >
        {isLoading ? (
          'Loading...'
        ) : (
          <>
            {formatInteger(String(count), '0')}
            <p className='text-lg font-semibold opacity-50 md:text-xl'>
              ({percentage}%)
            </p>
          </>
        )}
      </div>
      <p className='long-text text-center font-medium text-white/50 md:text-lg'>
        {label}
      </p>
    </div>
  )
}

export default UserMetric
