import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { DeleteButton } from '@/components/Buttons/DeleteButton'
import ProtectedComponent from '@/components/ProtectedComponent'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface Props {
  id: string
  isSent?: boolean
}

const ToggleIsSent: React.FC<Props> = ({ id, isSent }) => {
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationKey: ['toggle-is-sent', id],
    mutationFn: async (id: string) => {
      await api.put(`admin/access-codes/${id}/handle-sent`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries()
      toast.success('Access code updated')
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage || 'An error occurred while updating the access code'
      )
    }
  })

  return (
    <ProtectedComponent permissionSlug='access_codes_update'>
      <DeleteButton
        title={isSent ? 'Mark as not sent' : 'Mark as sent'}
        onDelete={() => mutate(id)}
        question={
          isSent
            ? 'Are you sure you want to mark this access code as not sent?'
            : 'Are you sure you want to mark this access code as sent?'
        }
      />
    </ProtectedComponent>
  )
}

export default ToggleIsSent
