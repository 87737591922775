import { useQuery } from '@tanstack/react-query'
import { format } from 'date-fns'
import { useState } from 'react'
import { Link } from 'react-router-dom'

import { EditButton } from '@/components/Buttons/EditButton'
import { Drawer } from '@/components/Drawer'
import Icon from '@/components/Icon'
import { Loader } from '@/components/Loader'
import UserCard from '@/components/UserCard'
import { Section } from '@/components/UserDetails/Section'

import { IUser } from '@/types/account/user.interface'
import { KycStatus } from '@/types/enums'

import { returnKycIcon } from '@/utils/return-kyc-icon'

import ProtectedComponent from '../ProtectedComponent'

import UserActions from './Actions'
import api from '@/api'
import { cn } from '@/utils'

interface Props {
  user: IUser
  fromOtherPage?: boolean
}

const UserDetailsModal: React.FC<Props> = ({ user, fromOtherPage = false }) => {
  const [isOpened, setIsOpened] = useState<boolean>(false)

  const {
    data: userData,
    isLoading,
    error
  } = useQuery({
    queryKey: ['user', user.id],
    queryFn: async () => {
      const resp = await api<IUser>(`/admin/users/${user.id}`)

      return resp.data
    },
    enabled: isOpened
  })

  return (
    <>
      {fromOtherPage ? (
        <UserCard
          name={user.display_name || `@${user.username}`}
          nameClassName='text-foreground'
          avatar={user.avatar || ''}
          onPress={() => setIsOpened(true)}
        />
      ) : (
        <EditButton onClick={() => setIsOpened(true)} title='Edit' />
      )}
      <Drawer
        contentClassName='bg-gray800 border-solid border-gray500/50 w-full sm:w-[480px]'
        open={isOpened}
        position='right'
        onClose={() => setIsOpened(false)}
      >
        <Loader loading={isLoading} type='absolute' />
        {error ? (
          <div className='flex h-full flex-col items-center justify-center gap-1'>
            <p className='text-red500'>
              An error occurred while fetching the user data.
            </p>
            <p>{error.message}</p>
          </div>
        ) : !userData ? (
          <div className='flex h-full flex-col items-center justify-center gap-1'>
            <p className='text-red500'>No data found for this user.</p>
          </div>
        ) : (
          <div className='flex h-full flex-col gap-2 overflow-y-auto p-6 md:p-16 lg:gap-4'>
            <div className='flex flex-row items-center justify-between'>
              <p
                title={userData.id}
                className='long-text whitespace-nowrap pr-[16px] text-[24px] font-bold text-white'
              >
                ID: {userData.id}
              </p>
              <Icon
                icon='Close'
                onClick={() => setIsOpened(false)}
                className='cursor-pointer fill-white opacity-50 transition-opacity hover:opacity-80 active:opacity-100'
              />
            </div>
            <Section
              title='Details'
              avatar={userData.avatar || undefined}
              options={[
                {
                  label: 'Display Name',
                  rightContent: userData.display_name || '-'
                },
                { label: 'Username', rightContent: `@${userData.username}` },
                { label: 'Email', rightContent: userData.email },
                {
                  label: 'KYC Status',
                  rightContent: (
                    <p
                      className={cn(
                        'flex items-center gap-1 text-sm font-semibold text-lime500',
                        {
                          'text-red500':
                            userData.kyc_status === KycStatus.FAILED,
                          'text-orange500':
                            userData.kyc_status === KycStatus.STARTED,
                          'text-foreground/50':
                            userData.kyc_status === KycStatus.UNVERIFIED
                        }
                      )}
                    >
                      {returnKycIcon(userData.kyc_status, 'size-5')}
                      {userData.kyc_status}
                    </p>
                  )
                },
                {
                  label: 'Membership Type',
                  rightContent:
                    userData.membership_type === 'REGULAR'
                      ? 'Regular'
                      : 'Satoshi Plus'
                },
                {
                  label: 'Date Joined',
                  rightContent: format(userData.created_at, 'yyyy.MM.dd')
                }
              ]}
            />
            {userData?.ip_addresses && userData.ip_addresses.length > 0 && (
              <Section
                title='IP Addresses'
                options={[
                  {
                    label: 'All IP Addresses Blocked',
                    rightContent: userData?.is_all_ip_addresses_blocked
                      ? 'Yes'
                      : 'No'
                  },
                  {
                    label: 'IP Addresses',
                    selfStart: true,
                    rightContent: (
                      <div className='flex flex-col items-end gap-1'>
                        {userData.ip_addresses.map((ip, index) => (
                          <p
                            key={index}
                            className={cn(
                              'text-[14px] font-semibold text-green-500',
                              {
                                'text-red500': ip.is_blocked
                              }
                            )}
                          >
                            {ip.value}
                          </p>
                        ))}
                      </div>
                    )
                  }
                ]}
              />
            )}
            <ProtectedComponent permissionSlug='users_view'>
              <UserActions user={userData} />
              <Link
                to={`/users/${userData.id}`}
                className='w-full rounded-full bg-orange550/10 py-2 text-center text-lg font-bold text-orange550 transition-all'
              >
                View Full Details
              </Link>
            </ProtectedComponent>
          </div>
        )}
      </Drawer>
    </>
  )
}

export default UserDetailsModal
