import { DataTable } from '@/components/DataTable'
import ReferMetrics from '@/components/Metrics/Refer'
import Page from '@/components/Page'

import { columns } from './columns'
import { REFER_TABS } from '@/constants/tabs'

export const ReferUsers = () => {
  return (
    <Page
      permissionSlug='refer_view'
      pageTitle='Refer Transactions'
      tabs={REFER_TABS}
    >
      <ReferMetrics />
      <DataTable
        columns={columns}
        url='/admin/users'
        totalText='users'
        tableClassName='md:!max-h-[calc(100vh-550px)]'
        method='POST'
        defaultSortBy='referrals'
        defaultSortOrder='desc'
      />
    </Page>
  )
}
