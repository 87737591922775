import { useState } from 'react'

import { DataTable } from '@/components/DataTable'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue
} from '@/components/DataTable/Select'
import Icon from '@/components/Icon'
import Page from '@/components/Page'

import { CreateAccessCode } from './Create'
import { columns } from './columns'
import { USERS_TABS } from '@/constants/tabs'

export function AccessCodes() {
  const [isClaimed, setIsClaimed] = useState<'all' | 'claimed' | 'unclaimed'>(
    'all'
  )
  const [isSent, setIsSent] = useState<'all' | 'sent' | 'unsent'>('all')

  return (
    <Page
      permissionSlug='access_codes_view'
      pageTitle='Access Codes'
      header={<CreateAccessCode />}
      tabs={USERS_TABS}
    >
      <DataTable
        url='admin/access-codes/query'
        columns={columns}
        method='POST'
        body={{
          isClaimed:
            isClaimed === 'all'
              ? undefined
              : isClaimed === 'claimed'
                ? true
                : false,
          isSent:
            isSent === 'all' ? undefined : isSent === 'sent' ? true : false
        }}
      >
        <Select
          value={isClaimed}
          onValueChange={(val: 'all' | 'claimed' | 'unclaimed') =>
            setIsClaimed(val)
          }
        >
          <SelectTrigger className='mt-0 w-[180px] rounded-3xl'>
            <SelectValue placeholder='Select Claimed' />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={'all'}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='Unverified' className='size-4' />
                All
              </div>
            </SelectItem>
            <SelectItem value={'claimed'}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='Rejected' className='size-4' />
                Claimed
              </div>
            </SelectItem>
            <SelectItem value={'unclaimed'}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='Approved' className='size-4' />
                Unclaimed
              </div>
            </SelectItem>
          </SelectContent>
        </Select>
        <Select
          value={isSent}
          onValueChange={(val: 'all' | 'sent' | 'unsent') => setIsSent(val)}
        >
          <SelectTrigger className='mt-0 w-[180px] rounded-3xl'>
            <SelectValue placeholder='Select sent Status' />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={'all'}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='Unverified' className='size-4' />
                All
              </div>
            </SelectItem>
            <SelectItem value={'sent'}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='Rejected' className='size-4' />
                Sent
              </div>
            </SelectItem>
            <SelectItem value={'unsent'}>
              <div className='flex flex-row items-center gap-2'>
                <Icon icon='Approved' className='size-4' />
                Unsent
              </div>
            </SelectItem>
          </SelectContent>
        </Select>
      </DataTable>
    </Page>
  )
}
