export enum KycStatus {
  UNVERIFIED = 'UNVERIFIED',
  STARTED = 'STARTED',
  VERIFIED = 'VERIFIED',
  VERIFIED_NO_SSN = 'VERIFIED_NO_SSN',
  FAILED = 'FAILED'
}

export enum AccountType {
  STARTER = 'STARTER',
  FLASH = 'FLASH'
}

export enum MembershipType {
  REGULAR = 'REGULAR',
  SATOSHI_PLUS = 'SATOSHI_PLUS'
}

export enum BankAsset {
  USD = 'USD',
  BTC = 'BTC',
  LN = 'LN'
}

export enum PaymentProvider {
  PLAID = 'PLAID'
}

export enum BankConnectionStatus {
  STORING = 'STORING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  REFRESH_REQUIRED = 'REFRESH_REQUIRED',
  UNVERIFIED = 'UNVERIFIED',
  DELETING = 'DELETING',
  DELETED = 'DELETED'
}

export enum BankTransferType {
  FUNDING = 'FUNDING',
  BOOK = 'BOOK',
  CRYPTO = 'CRYPTO',
  INSTANT_FUNDING = 'INSTANT_FUNDING',
  LIGHTNING_PAYMENT = 'LIGHTNING_PAYMENT'
}

export enum BankSide {
  BUY = 'BUY',
  SELL = 'SELL',
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL'
}

export enum BankTransferStatus {
  STORING = 'STORING',
  PENDING = 'PENDING',
  REVIEWING = 'REVIEWING',
  COMPLETED = 'COMPLETED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED'
}

export enum BankPaymentRail {
  ACH = 'ACH',
  ACH_INSTANT = 'ACH_INSTANT',
  WIRE = 'WIRE'
}

export enum BankProvider {
  CYBRID = 'CYBRID',
  SFOX = 'SFOX',
  ZEROHASH = 'ZEROHASH'
}

export enum BankEnvironment {
  PRODUCTION = 'PRODUCTION',
  SANDBOX = 'SANDBOX'
}

export enum BankCustomerStatus {
  CREATED = 'CREATED',
  STORING = 'STORING',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
  REJECTED = 'REJECTED',
  FROZEN = 'FROZEN'
}

export enum BankFundingSide {
  DEPOSIT = 'DEPOSIT',
  WITHDRAWAL = 'WITHDRAWAL'
}

export enum BankTradeStatus {
  STORING = 'STORING',
  PENDING = 'PENDING',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  SETTLING = 'SETTLING',
  FAILED = 'FAILED'
}

export enum BankSymbol {
  BTC_USD = 'BTC_USD'
}

export enum TransactionTypeOnchain {
  NORMAL = 'NORMAL',
  TAXMAN = 'TAXMAN',
  COMMISSION = 'COMMISSION'
}

export enum OnchainTransactionStatus {
  INITIATED = 'INITIATED',
  MEMPOOL = 'MEMPOOL',
  BLOCKCHAIN = 'BLOCKCHAIN',
  CONFIRMED = 'CONFIRMED',
  FAILED = 'FAILED'
}

export enum TransactionTypeLightning {
  NORMAL = 'NORMAL',
  TAXMAN = 'TAXMAN',
  COMMISSION = 'COMMISSION'
}

export enum NewWalletType {
  STARTER = 'STARTER',
  EXCHANGE = 'EXCHANGE',
  FLASH = 'FLASH',
  STASH = 'STASH'
}
