import { useMutation, useQueryClient } from '@tanstack/react-query'
import toast from 'react-hot-toast'

import { DeleteButton } from '@/components/Buttons/DeleteButton'
import ProtectedComponent from '@/components/ProtectedComponent'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface Props {
  id: string
}

const DeleteMember: React.FC<Props> = ({ id }) => {
  const queryClient = useQueryClient()

  const { mutate } = useMutation({
    mutationKey: ['deleteVideo'],
    mutationFn: async (id: string) => {
      await api.delete(`admin/admins/${id}`)
    },
    onSuccess: () => {
      queryClient.invalidateQueries()
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage || 'An error occurred while deleting the member.'
      )
    }
  })

  return (
    <ProtectedComponent permissionSlug='admins_delete'>
      <DeleteButton title='Delete' onDelete={() => mutate(id)} />
    </ProtectedComponent>
  )
}

export default DeleteMember
