import React, { PropsWithChildren } from 'react'
import { Helmet } from 'react-helmet'

import Tabs from '@/components/Page/Tabs'
import ProtectedComponent from '@/components/ProtectedComponent'

import { ITab } from '@/constants/tabs'

interface Props {
  permissionSlug?: string
  pageTitle: string
  title?: string
  tabs?: ITab[]
  header?: React.ReactNode
}

const Page: React.FC<PropsWithChildren<Props>> = ({
  permissionSlug,
  pageTitle,
  title,
  tabs,
  header,
  children
}) => {
  return (
    <ProtectedComponent isPage permissionSlug={permissionSlug}>
      <Helmet>
        <title>STBA Admin - {pageTitle}</title>
      </Helmet>
      <section className='flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-4 lg:px-8 lg:pb-10'>
        {title && (
          <h1 className='border-b border-foreground/15 pb-2 text-xl font-bold text-white md:pb-4 md:text-2xl lg:text-3xl'>
            {title}
          </h1>
        )}
        {tabs || header ? (
          <div className='flex flex-col gap-2 lg:min-h-10 lg:flex-row lg:items-center lg:justify-between lg:gap-4'>
            {tabs && <Tabs tabs={tabs} />}
            {header}
          </div>
        ) : null}
        {children}
      </section>
    </ProtectedComponent>
  )
}

export default Page
