import { Switch } from '@nextui-org/react'
import { useMutation } from '@tanstack/react-query'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import Field from '@/components/Field'
import { FormButton } from '@/components/FormButton'

import { useActions } from '@/hooks/use-actions'
import { useAuth } from '@/hooks/use-auth'

import { IAdmin, IUpdateSelf } from '@/types/account/admin.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

export function Settings() {
  const { updateAdmin } = useActions()
  const { mutate, isPending } = useMutation({
    mutationKey: ['changePassword'],
    mutationFn: async (data: IUpdateSelf) => {
      const resp = await api.patch('/admin/update-profile', data)

      return resp.data
    },
    onSuccess: (admin: IAdmin) => {
      toast.success('Data changed successfully')
      updateAdmin(admin)
    },
    onError: error => {
      const errorResponse = errorCatch(error)
      toast.error(errorResponse || 'An error occurred')
    }
  })

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm<IUpdateSelf>({
    mode: 'onChange',
    defaultValues: {
      name: '',
      password: '',
      host: '',
      port: '',
      is_subscribed_bank_webhooks: false
    }
  })

  const { admin } = useAuth()

  useEffect(() => {
    if (admin) {
      reset({
        name: admin.name,
        password: undefined,
        host: admin.host,
        port: admin.port,
        is_subscribed_bank_webhooks: admin.is_subscribed_bank_webhooks
      })
    }
  }, [admin, reset])

  const onSubmit: SubmitHandler<IUpdateSelf> = async data => mutate(data)

  return (
    <>
      <Helmet>
        <title>STBA Admin - Settings</title>
      </Helmet>
      <section className='flex w-full flex-col gap-2 p-2 md:gap-4 md:px-4 md:py-6 lg:px-8 lg:py-10'>
        <section className='flex w-full flex-wrap justify-between border-b border-white/15 pb-7'>
          <h1 className='text-2xl font-bold text-white md:text-3xl'>
            Settings
          </h1>
        </section>

        <form
          onSubmit={handleSubmit(onSubmit)}
          className='flex max-w-lg flex-col items-start justify-start gap-4'
        >
          <Field
            title='Password'
            type='password'
            placeholder='Password'
            autoComplete='new-password'
            {...register('password', {
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters'
              },
              pattern: {
                message:
                  'Password must contain at least one uppercase letter, one lowercase letter, one number and one special character',
                value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/
              }
            })}
            className='!mt-0'
            error={errors.password?.message}
          />
          <Field
            title='Name'
            placeholder='Name'
            {...register('name', {})}
            error={errors.name?.message}
          />
          <Field
            title='Host'
            placeholder='host'
            {...register('host', {
              pattern: {
                value: /^[a-zA-Z0-9.-]+$/,
                message: 'Invalid host'
              }
            })}
            error={errors.host?.message}
          />
          <Field
            title='Port'
            placeholder='port'
            {...register('port', {
              pattern: {
                value: /^[0-9]*$/,
                message: 'Invalid port'
              }
            })}
            error={errors.port?.message}
          />
          <Controller
            name='is_subscribed_bank_webhooks'
            control={control}
            render={({ field }) => (
              <label className='mt-5 flex w-full cursor-pointer items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                <p>Redirect Webhooks</p>
                <Switch
                  color='success'
                  isSelected={field.value}
                  onValueChange={field.onChange}
                />
              </label>
            )}
          />
          <FormButton
            onClick={handleSubmit(onSubmit)}
            preventDefault
            stopPropagation
            disabled={isPending}
            title='Save'
            className='mt-2 bg-orange550 text-white'
          />
        </form>
      </section>
    </>
  )
}
