import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'

import { DataTable } from '@/components/DataTable'
import { Section } from '@/components/InfoSection'
import { SubDescriptionSectionItem } from '@/components/InfoSection/SectionItem/SubDescriptionSectionItem'
import Page from '@/components/Page'

import { EarnDataInterface } from '@/types/metrics/metrics.interface'

import styles from '../Metrics/metrics.module.css'

import api from '@/api'
import { columns } from '@/routes/Earn/columns'
import { formatEarnData } from '@/routes/Earn/earnFormater'
import { cn } from '@/utils'

const Earn = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['metricsEarn'],
    queryFn: async () => {
      const resp = await api.get<EarnDataInterface>('admin/metrics/earn')

      return resp.data
    },
    retry: 1,
    enabled: true
  })

  const formattedEarnData = useMemo(() => {
    return formatEarnData(data)
  }, [data])

  return (
    <Page permissionSlug='earn_view' pageTitle='Earn'>
      <Section>
        <SubDescriptionSectionItem
          labelClassName='!text-purple400'
          label={formattedEarnData.earnWalletBalance.label}
          description={formattedEarnData.earnWalletBalance.description}
          subDescription={formattedEarnData.earnWalletBalance.subDescription}
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnTwo
          )}
          loading={isLoading}
          error={isError ? 'Could not get Earn metrics' : undefined}
        />
        <SubDescriptionSectionItem
          labelClassName='!text-purple400'
          label={formattedEarnData.totalUnclaimed.label}
          description={formattedEarnData.totalUnclaimed.description}
          subDescription={formattedEarnData.totalUnclaimed.subDescription}
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnTwo
          )}
          loading={isLoading}
          error={isError ? 'Could not get Earn metrics' : undefined}
        />
        <SubDescriptionSectionItem
          labelClassName='!text-lime500'
          label={formattedEarnData.totalFuturePayout.label}
          description={formattedEarnData.totalFuturePayout.description}
          subDescription={formattedEarnData.totalFuturePayout.subDescription}
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnThree
          )}
          loading={isLoading}
          error={isError ? 'Could not get Earn metrics' : undefined}
        />
        <SubDescriptionSectionItem
          labelClassName='!text-orange500'
          label={formattedEarnData.totalDailyEarnRate.label}
          description={formattedEarnData.totalDailyEarnRate.description}
          subDescription={formattedEarnData.totalDailyEarnRate.subDescription}
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnThree
          )}
          loading={isLoading}
          error={isError ? 'Could not get Earn metrics' : undefined}
        />
        <SubDescriptionSectionItem
          labelClassName='!text-orange500'
          label={formattedEarnData.totalPaidOut.label}
          description={formattedEarnData.totalPaidOut.description}
          subDescription={formattedEarnData.totalPaidOut.subDescription}
          className={cn(
            'w-full min-w-0 flex-none',
            styles.sectionItemColumnThree
          )}
          loading={isLoading}
          error={isError ? 'Could not get Earn metrics' : undefined}
        />
      </Section>

      <DataTable
        columns={columns}
        url='/admin/users'
        totalText='rank'
        tableClassName='md:!max-h-[calc(100vh-550px)]'
        defaultSortBy='rank'
        defaultSortOrder='asc'
        method='POST'
      />
    </Page>
  )
}

export default Earn
