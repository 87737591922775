import { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import Menu from '@/components/Menu'

import { ITab } from '@/constants/tabs'

interface Props {
  tabs: ITab[]
}

const Tabs: React.FC<Props> = ({ tabs }) => {
  const location = useLocation()
  const navigate = useNavigate()

  const [selectedTab, setSelectedTab] = useState<string>(
    tabs.find(tab => tab.url === location.pathname)?.url || tabs[0].url
  )

  return (
    <Menu
      setSelectedTab={tab => {
        setSelectedTab(tab)
        navigate(tab)
      }}
      selectedTab={selectedTab}
      tabs={tabs}
    />
  )
}

export default Tabs
