import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import { AddButton } from '@/components/Buttons/AddButton'
import { EditButton } from '@/components/Buttons/EditButton'
import Field from '@/components/Field'
import { FormButton } from '@/components/FormButton'
import { Loader } from '@/components/Loader'
import { Modal } from '@/components/Modal'
import ProtectedComponent from '@/components/ProtectedComponent'

import {
  IConfiguration,
  ICreateOrUpdateConfiguration
} from '@/types/configurations/configuration.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'

interface Props {
  initial?: IConfiguration
}

const CreateOrUpdateConfiguration: React.FC<Props> = ({ initial }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateOrUpdateConfiguration>({
    mode: 'onChange',
    defaultValues: initial || {
      name: '',
      value: '',
      description: ''
    }
  })

  useEffect(() => {
    reset(
      initial || {
        name: '',
        value: '',
        description: ''
      }
    )
  }, [initial, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-configuration' : 'add-configuration'],
    mutationFn: (data: ICreateOrUpdateConfiguration) =>
      api(
        initial ? `/admin/configuration/${initial.id}` : '/admin/configuration',
        {
          method: initial ? 'PATCH' : 'POST',
          data
        }
      ),
    onSuccess: () => {
      toast.success(
        `Configuration ${initial ? 'updated' : 'created'} successfully!`
      )
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage ||
          `An error occurred while ${initial ? 'updating' : 'creating'} configuration`
      )
    }
  })

  const onSubmit: SubmitHandler<ICreateOrUpdateConfiguration> = async data =>
    mutate(data)

  return (
    <ProtectedComponent permissionSlug='configurations_update'>
      {initial ? (
        <EditButton onClick={() => setIsOpen(true)} title='Edit' />
      ) : (
        <AddButton onClick={() => setIsOpen(true)} title='Add Configuration' />
      )}
      <Modal visible={isOpen} onClose={() => setIsOpen(false)}>
        <Loader type='absolute' loading={isPending} />
        <Modal.Header
          title={initial ? 'Edit Configuration' : 'Add Configuration'}
          closable
          className='mb-[30px]'
        />
        <Modal.Content>
          <form className='flex w-full flex-col gap-5'>
            <Field
              title='Name'
              placeholder='Name'
              className='!mt-0'
              disabled={!!initial}
              {...register('name', {
                required: 'Name is required',
                minLength: {
                  value: 3,
                  message: 'Name should be at least 3 characters'
                }
              })}
              error={errors.name?.message}
            />
            <Field
              title='Value'
              placeholder='Value'
              className='!mt-0'
              {...register('value', {
                required: 'Value is required'
              })}
              error={errors.value?.message}
            />
            <Field
              title='Description'
              placeholder='Description'
              className='!mt-0'
              {...register('description')}
              error={errors.description?.message}
            />

            <FormButton
              type='submit'
              title={initial ? 'Save' : 'Create'}
              stopPropagation
              preventDefault
              className='mt-[40px] bg-orange550 text-white'
              onClick={handleSubmit(onSubmit)}
            />
          </form>
        </Modal.Content>
      </Modal>
    </ProtectedComponent>
  )
}

export default CreateOrUpdateConfiguration
