import { DataTable } from '@/components/DataTable'
import Page from '@/components/Page'

import { columns } from './columns'
import { BANK_TABS } from '@/constants/tabs'

const BankTrades: React.FC = () => {
  return (
    <Page
      permissionSlug='bank_trades_view'
      pageTitle='Bank Trades'
      tabs={BANK_TABS}
    >
      <DataTable url='admin/bank/trades' columns={columns} />
    </Page>
  )
}

export default BankTrades
