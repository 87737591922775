import { Switch } from '@nextui-org/react'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

import { AddButton } from '@/components/Buttons/AddButton'
import { EditButton } from '@/components/Buttons/EditButton'
import Field from '@/components/Field'
import { FormButton } from '@/components/FormButton'
import { Loader } from '@/components/Loader'
import { ErrorMessage } from '@/components/Message/ErrorMessage'
import { Modal } from '@/components/Modal'
import ProtectedComponent from '@/components/ProtectedComponent'

import { IAdmin, ICreateOrUpdateAdmin } from '@/types/account/admin.interface'

import { errorCatch } from '@/api/error'

import api from '@/api'
import RoleSelect from '@/routes/Organization/Roles/RoleSelect'
import ResetPassword from '@/routes/Organization/TeamMembers/ResetPassword'

interface Props {
  initial?: IAdmin
  url: string
}

const CreateOrUpdateMember: React.FC<Props> = ({ initial, url }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset
  } = useForm<ICreateOrUpdateAdmin>({
    mode: 'onChange',
    defaultValues: initial
      ? {
          name: initial.name,
          email: initial.email,
          roleId: initial.role_id,
          host: initial.host,
          port: initial.port,
          is_subscribed_bank_webhooks: initial.is_subscribed_bank_webhooks
        }
      : {
          name: '',
          email: '',
          roleId: undefined,
          host: '',
          port: '',
          is_subscribed_bank_webhooks: false
        }
  })

  useEffect(() => {
    reset(
      initial
        ? {
            name: initial.name,
            email: initial.email,
            roleId: initial.role_id,
            host: initial.host,
            port: initial.port,
            is_subscribed_bank_webhooks: initial.is_subscribed_bank_webhooks
          }
        : {
            name: '',
            email: '',
            roleId: undefined,
            host: '',
            port: '',
            is_subscribed_bank_webhooks: false
          }
    )
  }, [initial, reset])

  const queryClient = useQueryClient()

  const { isPending, mutate } = useMutation({
    mutationKey: [initial ? 'update-admin' : 'add-admin'],
    mutationFn: (data: ICreateOrUpdateAdmin) =>
      api(url, {
        method: initial ? 'PATCH' : 'POST',
        data
      }),
    onSuccess: () => {
      toast.success(`Admin ${initial ? 'updated' : 'created'} successfully!`, {
        id: initial ? 'update-admin' : 'add-admin'
      })
      reset()
      queryClient.invalidateQueries()
      setIsOpen(false)
    },
    onError: error => {
      const errorMessage = errorCatch(error)
      toast.error(
        errorMessage ||
          `An error occurred while ${initial ? 'updating' : 'creating'} the admin.`,
        {
          id: 'add-admin'
        }
      )
    }
  })

  const onSubmit: SubmitHandler<ICreateOrUpdateAdmin> = async data =>
    mutate(data)

  return (
    <>
      {initial ? (
        <ProtectedComponent permissionSlug='admins_update'>
          <EditButton onClick={() => setIsOpen(true)} title='Edit' />
        </ProtectedComponent>
      ) : (
        <ProtectedComponent permissionSlug='admins_create'>
          <AddButton title='Add Member' onClick={() => setIsOpen(true)} />
        </ProtectedComponent>
      )}
      <Modal visible={isOpen} onClose={() => setIsOpen(false)}>
        <Loader type='absolute' loading={isPending} />
        <Modal.Header
          title={initial ? 'Edit Team Member' : 'Add Team Member'}
          closable
          className='mb-[30px]'
        />
        <Modal.Content>
          <form className='flex flex-col gap-2'>
            <Field
              placeholder='name'
              {...register('name', {
                required: 'Member Name is required',
                minLength: {
                  value: 3,
                  message: 'Member Name should be at least 3 characters long'
                },
                maxLength: {
                  value: 100,
                  message: 'Member Name should be at most 100 characters long'
                }
              })}
              error={errors.name?.message}
            />
            <Field
              disabled={!!initial}
              placeholder='email'
              {...register('email', {
                required: 'Email is required',
                pattern: {
                  value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                  message: 'Invalid email'
                }
              })}
              error={errors.email?.message}
            />
            <div className='flex flex-col gap-2'>
              <Controller
                name='roleId'
                control={control}
                rules={{ required: 'Role is required' }}
                render={({ field }) => {
                  return (
                    <RoleSelect
                      roleId={field.value}
                      setRoleId={field.onChange}
                    />
                  )
                }}
              />
              {errors.roleId?.message && (
                <ErrorMessage message={errors.roleId.message} />
              )}
            </div>
            <Field
              placeholder='host'
              {...register('host', {
                pattern: {
                  value: /^[a-zA-Z0-9.-]+$/,
                  message: 'Invalid host'
                }
              })}
              error={errors.host?.message}
            />
            <Field
              placeholder='port'
              {...register('port', {
                pattern: {
                  value: /^[0-9]*$/,
                  message: 'Invalid port'
                }
              })}
              error={errors.port?.message}
            />
            <Controller
              name='is_subscribed_bank_webhooks'
              control={control}
              render={({ field }) => (
                <label className='mt-5 flex cursor-pointer items-center justify-between gap-2 text-base font-medium text-foreground/50'>
                  <p>Redirect Webhooks</p>
                  <Switch
                    color='success'
                    isSelected={field.value}
                    onValueChange={field.onChange}
                  />
                </label>
              )}
            />
            <FormButton
              onClick={handleSubmit(onSubmit)}
              preventDefault
              stopPropagation
              title={initial ? 'Update' : 'Add'}
              className='mt-10 bg-orange550 text-white'
            />
            {initial && (
              <ProtectedComponent permissionSlug='admins_reset_password'>
                <ResetPassword
                  admin={initial}
                  onSuccess={() => setIsOpen(false)}
                />
              </ProtectedComponent>
            )}
          </form>
        </Modal.Content>
      </Modal>
    </>
  )
}

export default CreateOrUpdateMember
